import React from 'react';
import { useTranslation } from 'react-i18next';
import './partnersPage.css';

const partners = [
    {
        name: 'Rescert',
        logo: require("../../../Images/ecovolution-partner-rescert.png"),
        translationKey: "partnersPage.partners.rescert.description",
        website: 'https://www.rescert.be'
    },
    {
        name: 'Flux50',
        logo: require("../../../Images/redactor.jpg"),
        translationKey: "partnersPage.partners.flux50.description",
        website: 'https://www.flux50.com'
    },
    {
        name: 'Agoria',
        logo: require("../../../Images/AGORIA_LOGO_CMYK-POS.jpg"),
        translationKey: "partnersPage.partners.agoria.description",
        website: 'https://www.agoria.be'
    },
    {
        name: 'Nelectra',
        logo: require("../../../Images/Logo Nelectra 1410.png"),
        translationKey: "partnersPage.partners.nelectra.description",
        website: 'https://www.nelectra.be'
    },
    {
        name: 'Moorsele onderneemt',
        logo: require("../../../Images/logo-moorsele-onderneemt.png"),
        translationKey: "partnersPage.partners.moorsele.description",
        website: 'https://www.moorseleonderneemt.be'
    }
];

const sponsoredOrganizations = [
    {
        name: 'Volley Menen',
        logo: require("../../../Images/logo-volley-menen.png"),
        translationKey: "partnersPage.sponsored.volley.description",
        website: 'https://www.volleymenen.be'
    },
    {
        name: 'Meense Leie Schakers',
        logo: require("../../../Images/logo-meense-leie-schakers.png"),
        translationKey: "partnersPage.sponsored.chess.description",
        website: 'https://www.meenseleieschakers.be'
    }
];

const PartnersPage = () => {
    const { t } = useTranslation();

    return (
        <div className="partners-page">
            <h1>{t("partnersPage.header")}</h1>
            <div className="partners-grid">
                {partners.map((partner, index) => (
                    <div key={index} className="partner-card">
                        <div className="partner-logo-container">
                            <img
                                src={partner.logo}
                                alt={`${partner.name} logo`}
                                className="partner-logo"
                            />
                        </div>
                        <h2>{partner.name}</h2>
                        <p>{t(partner.translationKey)}</p>
                        <a href={partner.website} target="_blank" rel="noopener noreferrer" className="partner-link">
                            {t("partnersPage.visitWebsite")}
                        </a>
                    </div>
                ))}
            </div>

            <h1>{t("partnersPage.socialHeader")}</h1>
            <div className="sponsored-grid">
                {sponsoredOrganizations.map((organization, index) => (
                    <div key={index} className="partner-card">
                        <div className="partner-logo-container">
                            <img
                                src={organization.logo}
                                alt={`${organization.name} logo`}
                                className="partner-logo"
                            />
                        </div>
                        <h2>{organization.name}</h2>
                        <p>{t(organization.translationKey)}</p>
                        <a href={organization.website} target="_blank" rel="noopener noreferrer" className="partner-link">
                            {t("partnersPage.visitWebsite")}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnersPage;
