import './App.css';
import './i18n';
import CookieBanner from './components/HomePage/CookiePolicyPage/cookieBanner';
import { Route, Routes } from 'react-router-dom';

import {
    ContactRoute,
    ControllerRoute,
    CookieRoute,
    DashboardRoute,
    HomeRoute,
    NewsRoute,
    NewsDetailRoute,
    OplossingenRoute,
    BatteryTowerRoute,
    PartnersRoute,
    PowerblockRoute,
    ContainerRoute,
    SectorRoute,
    WebAdminPage,
    HomePageBodyRoute,
    RequestSimulationRoute,
    RequestAccountDeletionRoute,
    EBoilerRoute
} from './components/pages/pages';

function App() {
    return (
        <>
            <CookieBanner />
            <Routes>
                <Route path='/' element={<HomeRoute />} />
                <Route path='/AdminPage' element={<WebAdminPage />} />
                <Route path='/Contact' element={<ContactRoute />} />
                <Route path='/Controller' element={<ControllerRoute />} />
                <Route path='/Cookiebeleid' element={<CookieRoute />} />
                <Route path='/HomePage' element={<DashboardRoute />} />
                <Route path='/Nieuws' element={<NewsRoute />} />
                <Route path='/Nieuws/:id' element={<NewsDetailRoute />} />
                <Route path='/Oplossingen' element={<OplossingenRoute />} />
                <Route path='/Industrie' element={<SectorRoute sector='industrie' />} />
                <Route path='/Horeca' element={<SectorRoute sector='horeca' />} />
                <Route path='/Residentieel' element={<SectorRoute sector='residentieel' />} />
                <Route path='/Landbouw' element={<SectorRoute sector='landbouw' />} />
                <Route path='/BatteryTower' element={<BatteryTowerRoute />} />
                <Route path='/Powerblock' element={<PowerblockRoute />} />
                <Route path='/Partners' element={<PartnersRoute />} />
                <Route path='/Container' element={<ContainerRoute />} />
                <Route path='/BodyPage/:kader' element={<HomePageBodyRoute />} />
                <Route path='/SimulatieAanvragen' element={<RequestSimulationRoute />} />
                <Route path='/RequestAccountDeletion' element={<RequestAccountDeletionRoute />} />
                <Route path='/E-Boiler' element={<EBoilerRoute />} />
            </Routes>
        </>
    );
}

export default App;
