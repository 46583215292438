import React from "react";
import { useTranslation } from "react-i18next";
import "./cookiePage.css";

const CookiePage = () => {
    const { t } = useTranslation();

    return (
        <div className="cookiePage__body">
            <header className="cookiePage__header">
                {/* ================================================================
                STEEDS DE DATUM AANPASSEN ALS JE AANPASSINGEN MAAKT AAN DEZE PAGINA!
                ==================================================================== */}
                <p className="cookiePage__last-updated">
                    <strong>{t('cookiePolicy.lastUpdated')}</strong> 28 januari 2025
                </p>
                <h1>{t('cookiePolicy.title')}</h1>
            </header>

            <section className="cookiePage__section">
                <p>{t('cookiePolicy.sections.intro.description')}</p>
                <p>{t('cookiePolicy.sections.intro.privacyImportance')}</p>
                <p>{t('cookiePolicy.sections.intro.consent')}</p>
                <p>{t('cookiePolicy.sections.intro.respect')}</p>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.dataCollection.title')}</h2>
                <p>{t('cookiePolicy.sections.dataCollection.methods')}</p>
                <ul>
                    <li>{t('cookiePolicy.sections.dataCollection.examples.0')}</li>
                    <li>{t('cookiePolicy.sections.dataCollection.examples.1')}</li>
                </ul>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.dataUsage.title')}</h2>
                <p>{t('cookiePolicy.sections.dataUsage.methods')}</p>
                <ul>
                    <li>{t('cookiePolicy.sections.dataUsage.purposes.0')}</li>
                    <li>{t('cookiePolicy.sections.dataUsage.purposes.1')}</li>
                    <li>{t('cookiePolicy.sections.dataUsage.purposes.2')}</li>
                </ul>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.cookies.title')}</h2>
                <p>{t('cookiePolicy.sections.cookies.description')}</p>
                <table className="cookie-table">
                    <thead>
                        <tr>
                            <th>{t('cookiePolicy.sections.cookies.tableHeaders.0')}</th>
                            <th>{t('cookiePolicy.sections.cookies.tableHeaders.1')}</th>
                            <th>{t('cookiePolicy.sections.cookies.tableHeaders.2')}</th>
                            <th>{t('cookiePolicy.sections.cookies.tableHeaders.3')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>darkmode</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.0.purpose')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.0.duration')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.0.provider')}</td>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.1.purpose')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.1.duration')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.1.provider')}</td>
                        </tr>
                        <tr>
                            <td>_ga_#</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.2.purpose')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.2.duration')}</td>
                            <td>{t('cookiePolicy.sections.cookies.examples.2.provider')}</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.rights.title')}</h2>
                <p>{t('cookiePolicy.sections.rights.methods')}</p>
                <ul>
                    <li>{t('cookiePolicy.sections.rights.list.0')}</li>
                    <li>{t('cookiePolicy.sections.rights.list.1')}</li>
                    <li>{t('cookiePolicy.sections.rights.list.2')}</li>
                    <li>{t('cookiePolicy.sections.rights.list.3')}</li>
                </ul>
                <p>
                    {t('cookiePolicy.sections.rights.contact1')}{' '}
                    <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a>. {t('cookiePolicy.sections.rights.contact2')}
                </p>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.dataSharing.title')}</h2>
                <p>{t('cookiePolicy.sections.dataSharing.description')}</p>
                <ul>
                    <li>{t('cookiePolicy.sections.dataSharing.examples.0')}</li>
                    <li>{t('cookiePolicy.sections.dataSharing.examples.1')}</li>
                </ul>
                <p>{t('cookiePolicy.sections.dataSharing.agreements')}</p>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.dataProtection.title')}</h2>
                <p>{t('cookiePolicy.sections.dataProtection.description')}</p>
            </section>

            <section className="cookiePage__section">
                <h2>{t('cookiePolicy.sections.policyChanges.title')}</h2>
                <p>{t('cookiePolicy.sections.policyChanges.description')}</p>
            </section>

            <footer className="cookiePage__footer">
                <p>
                    {t('cookiePolicy.sections.contact')}{' '}
                    <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a>.
                </p>
            </footer>
        </div>
    );
};

export default CookiePage;
