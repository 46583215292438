import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './controllerPage.css';
import grafiek from '../../../Images/Grafiek.png';
import controller from '../../../Images/Controller.png';

const ControllerPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const hash = window.location.hash;
        setTimeout(() => {
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView();
                }
            }
        }, 0);
    }, []);

    return (
        <div className="homePage__controller__container">
            <section className="homePage__controller__introduction">
                <h1>{t('controllerPage.title')}</h1>
                <p>{t('controllerPage.introduction.text')}</p>
            </section>

            <section className="homePage__controller__energy-management">
                <h2>{t('controllerPage.energyManagement.title')}</h2>
                <p>{t('controllerPage.energyManagement.overview')}</p>

                <h3>{t('controllerPage.energyManagement.sections.selfConsumption.title')}</h3>
                <p>{t('controllerPage.energyManagement.sections.selfConsumption.text')}</p>
                <ul>
                    <li>
                        <strong>{t('controllerPage.energyManagement.sections.selfConsumption.items.storage')}</strong>:
                        {t('controllerPage.energyManagement.sections.selfConsumption.items.storageDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.energyManagement.sections.selfConsumption.items.savings')}</strong>:
                        {t('controllerPage.energyManagement.sections.selfConsumption.items.savingsDescription')}
                    </li>
                </ul>
                <img src={grafiek} alt="Grafiek" />

                <h3>{t('controllerPage.energyManagement.sections.purchaseOptimization.title')}</h3>
                <p>{t('controllerPage.energyManagement.sections.purchaseOptimization.text')}</p>
                <ul>
                    <li>
                        <strong>{t('controllerPage.energyManagement.sections.purchaseOptimization.items.purchase')}</strong>:
                        {t('controllerPage.energyManagement.sections.purchaseOptimization.items.purchaseDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.energyManagement.sections.purchaseOptimization.items.sale')}</strong>:
                        {t('controllerPage.energyManagement.sections.purchaseOptimization.items.saleDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.energyManagement.sections.purchaseOptimization.items.belpex')}</strong>:
                        {t('controllerPage.energyManagement.sections.purchaseOptimization.items.belpexDescription')}
                    </li>
                </ul>
            </section>

            <section id="firn-controller" className="homePage__controller__firn-controller">
                <div className="homePage__controller__content">
                    <div>
                        <h2>{t('controllerPage.firnController.title')}</h2>
                        <p>{t('controllerPage.firnController.text')}</p>
                    </div>
                    <img src={controller} alt="FIRN Controller" className="homePage__controller__controllerImage" />
                </div>
                <h3>{t('controllerPage.firnController.howItWorks.title')}</h3>
                <ul>
                    <li>
                        <strong>{t('controllerPage.firnController.howItWorks.items.solarPrediction')}</strong>:
                        {t('controllerPage.firnController.howItWorks.items.solarPredictionDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.firnController.howItWorks.items.consumptionPrediction')}</strong>:
                        {t('controllerPage.firnController.howItWorks.items.consumptionPredictionDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.firnController.howItWorks.items.adaptiveAI')}</strong>:
                        {t('controllerPage.firnController.howItWorks.items.adaptiveAIDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.firnController.howItWorks.items.realTimeAdjustments')}</strong>:
                        {t('controllerPage.firnController.howItWorks.items.realTimeAdjustmentsDescription')}
                    </li>
                </ul>
            </section>

            <section className="homePage__controller__additional-control">
                <h2>{t('controllerPage.additionalControl.title')}</h2>
                <p>{t('controllerPage.additionalControl.text')}</p>

                <h3>{t('controllerPage.additionalControl.sections.controlConsumers.title')}</h3>
                <p>{t('controllerPage.additionalControl.sections.controlConsumers.text')}</p>

                <h3>{t('controllerPage.additionalControl.sections.waterHeating.title')}</h3>
                <p>{t('controllerPage.additionalControl.sections.waterHeating.text')}</p>

                <h3>{t('controllerPage.additionalControl.sections.capacityManagement.title')}</h3>
                <p>{t('controllerPage.additionalControl.sections.capacityManagement.text')}</p>

                <h3>{t('controllerPage.additionalControl.sections.curtailmentStrategies.title')}</h3>
                <p>{t('controllerPage.additionalControl.sections.curtailmentStrategies.text')}</p>
                <ul>
                    <li>
                        <strong>{t('controllerPage.additionalControl.sections.curtailmentStrategies.items.increaseConsumption')}</strong>:
                        {t('controllerPage.additionalControl.sections.curtailmentStrategies.items.increaseConsumptionDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.additionalControl.sections.curtailmentStrategies.items.energyStorage')}</strong>:
                        {t('controllerPage.additionalControl.sections.curtailmentStrategies.items.energyStorageDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.additionalControl.sections.curtailmentStrategies.items.reducePVProduction')}</strong>:
                        {t('controllerPage.additionalControl.sections.curtailmentStrategies.items.reducePVProductionDescription')}
                    </li>
                    <li>
                        <strong>{t('controllerPage.additionalControl.sections.curtailmentStrategies.items.turnOffInverters')}</strong>:
                        {t('controllerPage.additionalControl.sections.curtailmentStrategies.items.turnOffInvertersDescription')}
                    </li>
                </ul>
            </section>
        </div>
    );
};

export default ControllerPage;
