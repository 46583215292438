import { useState } from "react";
import "./accountDeletionRequest.css";

export default function RequestAccountDeletion() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Account deletion requested for:", email);
    setSubmitted(true);
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <h1 className="heading">Request Account Deletion</h1>
          <p className="subheading">
            If you wish to delete your FirnEnergy account, please send an email
            to <strong>thomas@firnenergy.com</strong>. Your request will be processed, and you will receive a
            confirmation email.
          </p>
        </div>
      </div>
    </div>
  );
}
