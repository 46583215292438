import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './homePageBodyPage.css';
import content from './HomePageBodyContent';
import DayAheadPriceChart from '../dayAheadPrice/dayAheadPriceChart';

const HomePageBodyPage = () => {
    const { kader } = useParams();
    const { t } = useTranslation();
    const language = t('language');
    const data = content[`kader${kader}`];

    if (!data) {
        return <div>{t('error')[language]}</div>;
    }

    return (
        <div className="bodyPage__body">
            <h1 className="bodyPage__header">{data.title[language]}</h1>
            <p>{data.description[language]}</p>
            {kader === '2' && (
                <div className="bodyPage__graph-section">
                    <div className="bodyPage__graph">
                        <DayAheadPriceChart dateToday={new Date()} />
                    </div>
                </div>
            )}
            {data.sections && data.sections.map((section, index) => (
                <section key={index} className="bodyPage__section">
                    {section.image === 'EnergiePrijs.png' ? (
                        <div className="bodyPage__content">
                            <div className={`bodyPage__text ${section.styling?.className || ''}`}>
                                {section.title && <h2>{section.title[language]}</h2>}
                                {section.description && <p>{section.description[language]}</p>}
                                {section.list && (
                                    <ul>
                                        {section.list[language].map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {section.image && (
                                <div className="bodyPage__image">
                                    <img src={require(`../../../Images/${section.image}`)} alt={section.title || "Afbeelding"} />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={`bodyPage__text ${section.styling?.className || ''}`}>
                            {section.title && <h2>{section.title[language]}</h2>}
                            {section.description && <p>{section.description[language]}</p>}
                            {section.title1 && <h3>{section.title1[language]}</h3>}
                            {section.list && (
                                <ul>
                                    {section.list[language].map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            )}
                            {section.title2 && <h3>{section.title2[language]}</h3>}
                            {section.list2 && (
                                <ul>
                                    {section.list2[language].map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            )}
                            {section.description2 && <p>{section.description2[language]}</p>}
                            {section.image && (
                                <div className="bodyPage__image-fullwidth">
                                    <img src={require(`../../../Images/${section.image}`)} alt={section.title || "Afbeelding"} />
                                </div>
                            )}
                        </div>
                    )}
                    {section.points && section.points.map((item, idx) => (
                        <div key={idx} className="bodyPage__points-item">
                            <h3>{item.title[language]}</h3>
                            <p>{item.description[language]}</p>
                        </div>
                    ))}
                </section>
            ))}
        </div>
    );
};

export default HomePageBodyPage;
