import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./cookieBanner.css";

const CookieBanner = () => {
    const { t } = useTranslation();
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferences, setPreferences] = useState({
        necessary: true,
        analytics: false,
        marketing: false,
    });

    useEffect(() => {
        const savedConsent = JSON.parse(localStorage.getItem("cookieConsent"));
        if (!savedConsent) {
            setIsBannerVisible(true);
        } else {
            setPreferences(savedConsent);
            applyConsent(savedConsent);
        }
    }, []);

    const handleAcceptAll = () => {
        const consent = {
            necessary: true,
            analytics: true,
            marketing: true,
        };
        setPreferences(consent);
        localStorage.setItem("cookieConsent", JSON.stringify(consent));
        applyConsent(consent);
        setIsBannerVisible(false);
    };

    const handleRejectAll = () => {
        const consent = {
            necessary: true,
            analytics: false,
            marketing: false,
        };
        setPreferences(consent);
        localStorage.setItem("cookieConsent", JSON.stringify(consent));
        applyConsent(consent);
        setIsBannerVisible(false);
    };

    const handleSavePreferences = () => {
        localStorage.setItem("cookieConsent", JSON.stringify(preferences));
        applyConsent(preferences);
        setIsBannerVisible(false);
    };

    const applyConsent = (consent) => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("consent", "update", {
            ad_storage: consent.marketing ? "granted" : "denied",
            analytics_storage: consent.analytics ? "granted" : "denied",
        });
    };

    const handlePreferenceChange = (e) => {
        const { name, checked } = e.target;
        setPreferences((prev) => ({ ...prev, [name]: checked }));
    };

    return (
        isBannerVisible && (
            <div className="cookieBanner__container">
                <div className="cookieBanner__content">
                    <div className="cookieBanner__text">
                        <h2>{t("cookieBanner.header")}</h2>
                        <p dangerouslySetInnerHTML={{ __html: t("cookieBanner.description") }}></p>
                        {showPreferences && (
                            <div className="checkbox-container">
                                <div className="switch-wrapper">
                                    <span>{t("cookieBanner.preferences.necessary")}</span>
                                    <label className="switch">
                                        <input type="checkbox" checked disabled />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="switch-wrapper">
                                    <span>{t("cookieBanner.preferences.analytics")}</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="analytics"
                                            checked={preferences.analytics}
                                            onChange={handlePreferenceChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="switch-wrapper">
                                    <span>{t("cookieBanner.preferences.marketing")}</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="marketing"
                                            checked={preferences.marketing}
                                            onChange={handlePreferenceChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="cookieBanner__buttons">
                        {!showPreferences ? (
                            <>
                                <button onClick={handleAcceptAll} className="cookie-button">
                                    {t("cookieBanner.buttons.acceptAll")}
                                </button>
                                <button
                                    onClick={() => setShowPreferences(true)}
                                    className="cookie-button cookie-button-secondary"
                                >
                                    {t("cookieBanner.buttons.manageSettings")}
                                </button>
                            </>
                        ) : (
                            <>
                                <button onClick={handleAcceptAll} className="cookie-button">
                                    {t("cookieBanner.buttons.acceptAll")}
                                </button>
                                <button onClick={handleSavePreferences} className="cookie-button">
                                    {t("cookieBanner.buttons.savePreferences")}
                                </button>
                                <button
                                    onClick={handleRejectAll}
                                    className="cookie-button cookie-button-danger"
                                >
                                    {t("cookieBanner.buttons.rejectAll")}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default CookieBanner;
