import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const baseImageUrl = process.env.REACT_APP_BASE_URL;

export const useNews = (language) => {
    const { t } = useTranslation();
    const [newsItems, setNewsItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`${apiUrl}/news?language=${language}`);
                if (!response.ok) {
                    throw new Error(t('useNews.error1'));
                }
                const data = await response.json();

                const processedNews = data.map((item) => ({
                    ...item,
                    mainImage: isValidUrl(item.mainImage) 
                        ? item.mainImage 
                        : `${baseImageUrl}${item.mainImage}`,
                }));

                setNewsItems(processedNews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [language]);

    return { newsItems, loading, error };
};

export const useNewsById = (id, language) => {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await fetch(`${apiUrl}/news/${id}?language=${language}`);
                if (!response.ok) {
                    throw new Error(t('useNews.error2'));
                }
                const data = await response.json();

                const mainImage = data.mainImage.startsWith('http') 
                    ? data.mainImage 
                    : `${process.env.REACT_APP_BASE_URL}${data.mainImage}`;
                const additionalImages = data.additionalImages.map((img) =>
                    img.startsWith('http') ? img : `${process.env.REACT_APP_BASE_URL}${img}`
                );

                setArticle({ ...data, mainImage, additionalImages });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [id, language]);

    return { article, loading, error };
};

export const formatDate = (date) => {
    const validDate = date instanceof Date ? date : new Date(date);

    if (isNaN(validDate)) {
        throw new Error(t('useNews.error3'));
    }

    const day = String(validDate.getDate()).padStart(2, '0');
    const month = String(validDate.getMonth() + 1).padStart(2, '0');
    const year = validDate.getFullYear();

    return `${day}.${month}.${year}`;
};