import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNews, formatDate } from '../../../Utils/useNews';
import './homePageNews.css';
import defaultLogo from '../../../Images/firn-hor.png';

const HomePageNews = () => {
    const { t } = useTranslation();
    const { newsItems } = useNews();
    const [index, setIndex] = useState(0);
    const [width, setWidth] = useState(0);
    const trackRef = useRef(null);
    const carouselRef = useRef(null);

    const latestNewsItems = newsItems.slice(0, 9);

    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {
                setWidth(carouselRef.current.offsetWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNext = (e) => {
        e.preventDefault();
        setIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrev = () => {
        setIndex((prevIndex) => prevIndex - 1);
    };

    const showPrevButton = index > 0;
    const hideNextButton = trackRef.current && (trackRef.current.scrollWidth - index * width <= width);

    return (
        <div className="homePage__news__container" id='homePage__news'>
            <div className="homePage__news__header">
                <p className='homePage__news__subtitle'><b>{t('homePageNews.follow')}</b> FIRN energy</p>
                <h1>{t('homePageNews.latestNews')}</h1>
                <p className="homePage__news__text">{t('homePageNews.description')}</p>
                <p className='homePage__news__link'><a href="/Nieuws">{t('homePageNews.readMore')} <i className='fas fa-arrow-right' /></a></p>
            </div>

            <div className="homePage__news__carousel-container" ref={carouselRef}>
                <div className="homePage__news__inner-carousel">
                    <div
                        className="homePage__news__track"
                        ref={trackRef}
                        style={{ transform: `translateX(${-index * width}px)` }}
                    >
                        {latestNewsItems.map((item, index) => (
                            <div key={item.id || index} className="homePage__news__card-container">
                                <div className="homePage__news__card">
                                    <img
                                        src={item.mainImage || defaultLogo}
                                        alt={`Nieuws ${item.id}`}
                                        onError={(e) => {
                                            e.target.src = defaultLogo;
                                            e.target.onerror = null;
                                        }}
                                    />
                                    <p className="homePage__news__date">{formatDate(item.createdAt)}</p>
                                    <p className="homePage__news__text">{item.shortDescription}</p>
                                    <p className="homePage__news__hashtags">{item.hashtags}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="homePage__news__nav">
                        {showPrevButton && (
                            <button className={`homePage__news__prev ${showPrevButton ? 'show' : ''}`} onClick={handlePrev}>
                                <i className="fas fa-arrow-left fa-2x"></i>
                            </button>
                        )}
                        {!hideNextButton && (
                            <button className={`homePage__news__next ${!hideNextButton ? 'show' : ''}`} onClick={handleNext}>
                                <i className="fas fa-arrow-right fa-2x"></i>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageNews;
