import React from "react";
import "./eBoilerPage.css";
import banner from "./../../../Images/Banner+datum-Amsterdam-NL-750x150px.jpg";

const EBoilerPage = () => {
  return (
    <div className="eboiler__container">
      <img alt="banner" src={banner} className="eboiler__image" />

      <section className="eboiler__section">
        <h2 className="eboiler__section-title">Wat is een e-boiler?</h2>
        <p className="eboiler__text">
          Een e-boiler werkt op elektriciteit en is een duurzame keuze voor
          warm water. Dankzij de compacte vorm en eenvoudige installatie is
          het een ideale oplossing voor woningen en bedrijven.
        </p>
      </section>

      <section className="eboiler__benefits">
        <h2 className="eboiler__section-title">Waarom kiezen voor een e-boiler?</h2>
        <ul className="eboiler__list">
          <li>⚡ Energiezuinig en milieuvriendelijk</li>
          <li>🔧 Compact en eenvoudig te installeren</li>
          <li>🌞 Perfect te combineren met zonnepanelen</li>
          <li>💰 Bespaar op je energiekosten</li>
        </ul>
      </section>

      <section className="eboiler__event">
        <h2 className="eboiler__section-title">Bezoek ons op de Solar Solutions beurs in Amsterdam!</h2>
        <p>
          FIRN Energy neemt deel aan <strong>Solar Solutions Amsterdam</strong>,
          de vakbeurs voor hernieuwbare energie in Nederland.
        </p>
        <p>
          In de razendsnel veranderende energie-sector is het belangrijk toekomstbestendig te blijven.
          Met meer dan 500 innovaties en 100 praktische seminars over de nieuwste ontwikkelingen rondom
          zonnepanelen, batterijen, EV Charging en Green HVAC biedt Solar Solutions Amsterdam alles wat
          nodig is om mee te gaan in deze extreem dynamische markt.
        </p>
        <p>
          Kom langs op onze stand <strong>M13</strong> van <strong>11 t.e.m. 13 maart 2025</strong> en ontdek hoe e-boilers
          jouw energieverbruik kunnen optimaliseren.
        </p>
        <a
          href="https://www.solarsolutions.nl/partner/firnsolar/"
          className="eboiler__button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Registreer hier voor een gratis ticket
        </a>
      </section>
    </div>
  );
};

export default EBoilerPage;