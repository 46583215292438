import React from 'react';
import { useTranslation } from 'react-i18next';
import './container.css';

import container from '../../../../Images/container.png';
import containerVideo from '../../../../Images/Container.mp4';

const Container = () => {
    const { t } = useTranslation();

    return (
        <div className="firnContainer__container">
            <section className="firnContainer__intro">
                <h1>{t('container.containerTitle')}</h1>
                <p>{t('container.containerDescription')}</p>
            </section>

            <section className="firnContainer__video">
                <h2>{t('container.containerTitle2')}</h2>
                <video autoPlay loop muted>
                    <source src={containerVideo} type="video/mp4" />
                    {t('container.containerError')}
                </video>
            </section>

            <section className="firnContainer__features">
                <h2>{t('container.containerFeatures')}</h2>
                <ul>
                    <li><strong>{t('container.feature1')}:</strong> {t('container.feature1Description')}</li>
                    <li><strong>{t('container.feature2')}:</strong> {t('container.feature2Description')}</li>
                    <li><strong>{t('container.feature3')}:</strong> {t('container.feature3Description')}</li>
                    <li><strong>{t('container.feature4')}:</strong> {t('container.feature4Description')}</li>
                </ul>
            </section>

            <section className="firnContainer__image">
                <img src={container} alt="FIRN Container" />
            </section>
        </div>
    );
}

export default Container;