export const oplossingenItems = [
    {
        id: 1,
        naam: {
            nl: "Batterij Toren",
            en: "Battery Tower",
            fr: "Tour de Batterie",
            de: "Batterieturm"
        },
        foto: require("../../../Images/batteryStack.png"),
        korteBeschrijving: {
            nl: "De FIRN Batterij Toren biedt betrouwbare energieopslag met flexibele schaalbaarheid en geavanceerde energiebeheeropties.",
            en: "The FIRN Battery Tower offers reliable energy storage with flexible scalability and advanced energy management options.",
            fr: "La tour de batterie FIRN offre un stockage d'énergie fiable avec une évolutivité flexible et des options avancées de gestion de l'énergie.",
            de: "Der FIRN Battery Tower bietet zuverlässige Energiespeicherung mit flexibler Skalierbarkeit und fortschrittlichen Energieverwaltungsoptionen."
        },
        voordelen: [
            {
                nl: "Ideaal voor kleine industrieën",
                en: "Ideal for small industries",
                fr: "Idéal pour les petites industries",
                de: "Ideal für kleine Industrien"
            },
            {
                nl: "Voor binnentoepassingen",
                en: "For indoor applications",
                fr: "Pour les applications intérieures",
                de: "Für Innenanwendungen"
            },
            {
                nl: "Buitenkast beschikbaar",
                en: "Outdoor cabinet available",
                fr: "Armoire extérieure disponible",
                de: "Außenschrank verfügbar"
            }
        ],
        link: "BatteryTower"
    },
    {
        id: 2,
        naam: {
            nl: "Powerblock",
            en: "Powerblock",
            fr: "Powerblock",
            de: "Powerblock"
        },
        foto: require("../../../Images/powerblock.png"),
        korteBeschrijving: {
            nl: "De alles-in-één vloeistof gekoelde ESS-kast maakt gebruik van geavanceerde vloeistofkoeling op kastniveau.",
            en: "The all-in-one liquid-cooled ESS cabinet uses advanced liquid cooling at the cabinet level.",
            fr: "L'armoire ESS tout-en-un refroidie par liquide utilise un refroidissement liquide avancé au niveau du boîtier.",
            de: "Der All-in-One flüssigkeitsgekühlte ESS-Schrank verwendet fortschrittliche Flüssigkeitskühlung auf Schrankebene."
        },
        voordelen: [
            {
                nl: "Krachtige energieopslag",
                en: "Powerful energy storage",
                fr: "Stockage d'énergie puissant",
                de: "Leistungsstarker Energiespeicher"
            },
            {
                nl: "Langdurige prestaties",
                en: "Long-lasting performance",
                fr: "Performance durable",
                de: "Langfristige Leistung"
            },
            {
                nl: "Geavanceerde koeling",
                en: "Advanced cooling",
                fr: "Refroidissement avancé",
                de: "Erweiterte Kühlung"
            }
        ],
        link: "Powerblock"
    },
    {
        id: 3,
        naam: {
            nl: "Controller",
            en: "Controller",
            fr: "Contrôleur",
            de: "Controller"
        },
        foto: require("../../../Images/Controller.png"),
        korteBeschrijving: {
            nl: "De FIRN-controller is een slim energiebeheersysteem dat dynamische energietarieven en -verbruik optimaliseert.",
            en: "The FIRN controller is a smart energy management system that optimizes dynamic energy rates and consumption.",
            fr: "Le contrôleur FIRN est un système de gestion énergétique intelligent qui optimise les tarifs énergétiques dynamiques et la consommation.",
            de: "Der FIRN-Controller ist ein intelligentes Energiemanagementsystem, das dynamische Energieraten und -verbrauch optimiert."
        },
        voordelen: [
            {
                nl: "Slim beheer van energieverbruik",
                en: "Smart energy consumption management",
                fr: "Gestion intelligente de la consommation d'énergie",
                de: "Intelligentes Energiemanagement"
            },
            {
                nl: "Dynamische aansturing van apparaten",
                en: "Dynamic control of devices",
                fr: "Contrôle dynamique des appareils",
                de: "Dynamische Steuerung von Geräten"
            },
            {
                nl: "Eenvoudige integratie met meerdere systemen",
                en: "Easy integration with multiple systems",
                fr: "Intégration facile avec plusieurs systèmes",
                de: "Einfache Integration mit mehreren Systemen"
            }
        ],
        link: "/Controller#firn-controller"
    },
    {
        id: 4,
        naam: {
            nl: "Container",
            en: "Container",
            fr: "Conteneur",
            de: "Container"
        },
        foto: require("../../../Images/container.png"),
        korteBeschrijving: {
            nl: "De FIRN Container is een robuuste, op maat gemaakte oplossing voor energieopslag. Biedt duurzaamheid, bescherming en schaalbaarheid voor elke klant.",
            en: "The FIRN Container is a robust, custom-made energy storage solution. Provides durability, protection, and scalability for every customer.",
            fr: "Le conteneur FIRN est une solution de stockage d'énergie robuste et sur mesure. Il offre durabilité, protection et évolutivité pour chaque client.",
            de: "Der FIRN-Container ist eine robuste, maßgeschneiderte Lösung für die Energiespeicherung. Bietet Haltbarkeit, Schutz und Skalierbarkeit für jeden Kunden."
        },
        voordelen: [
            {
                nl: "Op maat gemaakt",
                en: "Custom-made",
                fr: "Sur mesure",
                de: "Maßgeschneidert"
            },
            {
                nl: "Schaalbaar voor grote projecten",
                en: "Scalable for large projects",
                fr: "Scalable pour de grands projets",
                de: "Skalierbar für große Projekte"
            },
            {
                nl: "Robuust en weersbestendig",
                en: "Robust and weather-resistant",
                fr: "Robuste et résistant aux intempéries",
                de: "Robust und wetterbeständig"
            }
        ],
        link: "Container"
    }
];
