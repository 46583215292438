import React from 'react';
import { useTranslation } from 'react-i18next';
import './batteryTower.css';

import batteryTower from '../../../../Images/batteryStack.png';
import installedTower from '../../../../Images/installedTower.jpg';
import singleBattery from '../../../../Images/singleBattery.png';
import outdoorCabinet from '../../../../Images/outdoorCabinet.png';

const BatteryTower = () => {
    const { t } = useTranslation();

    return (
        <div className="batteryPage__container">
            <section className="batteryPage__introduction">
                <h1>{t('batteryTower.batteryTitle')}</h1>
                <div className="batteryPage__intro-content">
                    <img src={singleBattery} alt="Batterij uit de toren" className="batteryPage__single-battery" />
                    <p>{t('batteryTower.batteryDescription')}</p>
                </div>
            </section>

            <section className="batteryPage__tower">
                <div className="batteryPage__tower-content">
                    <div className="batteryPage__tower-text">
                        <h2>{t('batteryTower.towerTitle')}</h2>
                        <p>{t('batteryTower.towerDescription1')}</p>
                        <p>{t('batteryTower.towerDescription2')}</p>
                        <h3>{t('batteryTower.whyChoose')}</h3>
                        <ul>
                            <li><strong>{t('batteryTower.storage')}:</strong> {t('batteryTower.storageDescription')}</li>
                            <li><strong>{t('batteryTower.independence')}:</strong> {t('batteryTower.independenceDescription')}</li>
                            <li><strong>{t('batteryTower.advancedManagement')}:</strong> {t('batteryTower.advancedManagementDescription')}</li>
                            <li><strong>{t('batteryTower.scaling')}:</strong> {t('batteryTower.scalingDescription')}</li>
                        </ul>
                    </div>
                    <div className="batteryPage__tower-image">
                        <img src={batteryTower} alt="Batterij Toren" />
                    </div>
                </div>
            </section>

            <div className="batteryPage__installation-and-cabinet">
                <div className="batteryPage__installation-content">
                    <section className="batteryPage__installed-tower">
                        <h2>{t('batteryTower.installation')}</h2>
                        <div className="batteryPage__installed-content">
                            <img src={installedTower} alt="Batterij Toren bij een klant" className="installed-tower-image" />
                            <p>{t('batteryTower.installationDescription')}</p>
                        </div>
                    </section>

                    <section className="batteryPage__cabinet">
                        <h2>{t('batteryTower.cabinet')}</h2>
                        <div className="batteryPage__cabinet-content">
                            <p>{t('batteryTower.cabinetDescription')}</p>
                            <ul>
                                <li>{t('batteryTower.cabinetFeature1')}</li>
                                <li>{t('batteryTower.cabinetFeature2')}</li>
                                <li>{t('batteryTower.cabinetFeature3')}</li>
                            </ul>
                            <p>{t('batteryTower.cabinetConclusion')}</p>
                        </div>
                    </section>
                </div>

                <div className="batteryPage__cabinet-image">
                    <img src={outdoorCabinet} alt="Outdoor Cabinet" />
                </div>
            </div>
        </div>
    );
}

export default BatteryTower;
