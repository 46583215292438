import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import './requestSimulationForm.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const RequestSimulationForm = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        companyName: '',
        contactPerson: '',
        address: '',
        phone: '',
        email: '',
        annualUsage: '',
        pvProduction: '',
        injection: '',
        connectionPower: '',
        purchaseCostPerKwh: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submissionError, setSubmissionError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\+?[0-9]{10,}$/;
        return phonePattern.test(phone);
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.companyName) formErrors.companyName = t('requestSimulationForm.form.errors.required');
        if (!formData.contactPerson) formErrors.contactPerson = t('requestSimulationForm.form.errors.required');
        if (!formData.address) formErrors.address = t('requestSimulationForm.form.errors.required');
        if (!formData.phone) {
            formErrors.phone = t('requestSimulationForm.form.errors.required');
        } else if (!validatePhone(formData.phone)) {
            formErrors.phone = t('requestSimulationForm.form.errors.invalidPhone');
        }
        if (!formData.email) {
            formErrors.email = t('requestSimulationForm.form.errors.required');
        } else if (!validateEmail(formData.email)) {
            formErrors.email = t('requestSimulationForm.form.errors.invalidEmail');
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await fetch(`${apiUrl}/tracking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'submit_simulation_request' }),
            });
            console.log(t('requestSimulationForm.form.trackingError'));
        } catch (error) {
            console.error(t('requestSimulationForm.form.trackingError'), error);
        }

        if (validateForm()) {
            setIsLoading(true);
            setSubmissionError('');

            try {
                const response = await fetch(`${apiUrl}/simulation-request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    setIsSubmitted(true);
                    console.log(t('requestSimulationForm.form.successMessage'));
                    sendEmails();
                } else {
                    const errorData = await response.json();
                    setSubmissionError(errorData.error || t('requestSimulationForm.form.submissionError'));
                }
            } catch (error) {
                console.error(t('requestSimulationForm.form.submissionError'), error);
                setSubmissionError(t('requestSimulationForm.form.submissionError'));
            } finally {
                setIsLoading(false);
            }
        }
    };

    const sendEmails = () => {
        emailjs.send(
            // 'service_6tscwus',          // stage@firnenergy.com
            'service_hekx19p',      // it@firnenergy.com
            'request_simulation',
            formData,
            // 'VXY6bsFZJ5f63HRrv'         // stage@firnenergy.com
            'whZCVFXRoKwL6LE_B'     // it@firnenergy.com
        ).then(() => {
            console.log(t('requestSimulationForm.form.sendEmails.requestSuccess'));
        }).catch((error) => {
            console.error(t('requestSimulationForm.form.emailErrors.request'), error);
            setSubmissionError(t('requestSimulationForm.form.emailErrors.request'));
        });

        emailjs.send(
            // 'service_6tscwus',          // stage@firnenergy.com
            'service_hekx19p',        // it@firnenergy.com
            'confirmation_request',
            formData,
            // 'VXY6bsFZJ5f63HRrv'         // stage@firnenergy.com
            'whZCVFXRoKwL6LE_B'       // it@firnenergy.com
        ).then(() => {
            console.log(t('requestSimulationForm.form.sendEmails.confirmationSuccess'));
        }).catch((error) => {
            console.error(t('requestSimulationForm.form.emailErrors.confirmation'), error);
            setSubmissionError(t('requestSimulationForm.form.emailErrors.confirmation'));
        });
    };

    return (
        <div className="request-simulation-container">
            <h2>{t('requestSimulationForm.title')}</h2>
            <form onSubmit={handleSubmit} className="request-simulation-form">
                <div>
                    <label>{t('requestSimulationForm.form.labels.companyName')}</label>
                    <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} className={errors.companyName ? 'input-error' : ''} />
                    {errors.companyName && <span>{errors.companyName}</span>}
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.contactPerson')}</label>
                    <input type="text" name="contactPerson" value={formData.contactPerson} onChange={handleChange} className={errors.contactPerson ? 'input-error' : ''} />
                    {errors.contactPerson && <span>{errors.contactPerson}</span>}
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.address')}</label>
                    <input type="text" name="address" value={formData.address} onChange={handleChange} className={errors.address ? 'input-error' : ''} />
                    {errors.address && <span>{errors.address}</span>}
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.phone')}</label>
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} className={errors.phone ? 'input-error' : ''} />
                    {errors.phone && <span>{errors.phone}</span>}
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.email')}</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className={errors.email ? 'input-error' : ''} />
                    {errors.email && <span>{errors.email}</span>}
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.annualUsage')}</label>
                    <input type="number" name="annualUsage" value={formData.annualUsage} onChange={handleChange} />
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.pvProduction')}</label>
                    <input type="number" name="pvProduction" value={formData.pvProduction} onChange={handleChange} />
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.injection')}</label>
                    <input type="number" name="injection" value={formData.injection} onChange={handleChange} />
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.connectionPower')}</label>
                    <input type="number" name="connectionPower" value={formData.connectionPower} onChange={handleChange} />
                </div>
                <div>
                    <label>{t('requestSimulationForm.form.labels.purchaseCostPerKwh')}</label>
                    <input type="number" step="0.01" name="purchaseCostPerKwh" value={formData.purchaseCostPerKwh} onChange={handleChange} />
                </div>
                <button type="submit" disabled={isLoading || isSubmitted}>
                    {isLoading ? t('requestSimulationForm.form.button.loading') : isSubmitted ? t('requestSimulationForm.form.button.submitted') : t('requestSimulationForm.form.button.submit')}
                </button>
                {submissionError && <p className="error-message">{submissionError}</p>}
            </form>
        </div>
    );
};

export default RequestSimulationForm;
