import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import './homePageNavbar.css';
import kleinLogo from '../../../Images/embleem.png';
import logoMedium from '../../../Images/firn-hor.png';
import logo from '../../../Images/firn-ver.png';
import pdf1 from '../../../pdf/FIRN energy - Algemene voorwaarden.pdf';
import pdf2 from '../../../pdf/FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf';
import { trackClickToSimulationPage } from '../../../Utils/simulationHelper';

const HomePageNavBar = ({ isHomePage }) => {
    const { loginWithRedirect } = useAuth0();
    const { t, i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const languageMenuRef = useRef(null);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
        document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
    };

    const toggleLanguageMenu = () => {
        setLanguageMenuOpen(!languageMenuOpen);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguageMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
                setLanguageMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className={`homePage__navbar ${isHomePage ? 'homePage__navbar--homepage' : ''}`}>
            <div className={`homePage__navbar__left ${isHomePage ? "homePage__navbar__left--homepage" : ""}`}>
                <div className={isHomePage ? "homePage__navbar__logo" : "homePage__navbar__logo--medium"}>
                    <a href="/">
                        <img src={isHomePage ? kleinLogo : logoMedium} alt="FIRN energy Logo" />
                    </a>
                </div>
                <ul className="homePage__navbar__menu left-menu">
                    <li>
                        <a href="/Oplossingen" dangerouslySetInnerHTML={{ __html: t('homePageNavbar.solutions') }} />
                    </li>
                    <li className="homePage__navbar__controller">
                        <a href="/Controller" dangerouslySetInnerHTML={{ __html:t('homePageNavbar.controller') }} />
                    </li>
                </ul>
            </div>
            <div className="homePage__navbar__middle">
                {isHomePage && (
                    <img src={logo} alt="FIRN energy Logo" />
                )}
            </div>
            <div className={`homePage__navbar__right ${isHomePage ? "homePage__navbar__right--homepage" : ""}`}>
                <ul className="homePage__navbar__menu right-menu">
                    <li><a
                        href="/SimulatieAanvragen"
                        onClick={() => { trackClickToSimulationPage() }}
                    >{t('homePageNavbar.simulation')}</a></li>
                    <li><a href="/Nieuws">{t('homePageNavbar.news')}</a></li>
                    <li><a href="/Contact">{t('homePageNavbar.contact')}</a></li>
                </ul>
                <div className="homePage__navbar__dashboard">
                    <button href="#" className="homePage__dashboard__button"
                        onClick={() => loginWithRedirect({
                            appState: {
                                returnTo: "/HomePage"
                            }
                        })}>{t('homePageNavbar.dashboard')}</button>
                </div>
                <div 
                    className="homePage__navbar__language-dropdown"
                    ref={languageMenuRef}
                >
                    <div
                        className="language-selector"
                        onClick={toggleLanguageMenu}
                    >
                        {t('homePageNavbar.language')} <span className="arrow">▼</span>
                    </div>
                    {languageMenuOpen && (
                        <ul className="dropdown-menu">
                            <li onClick={() => changeLanguage('nl')}>NL</li>
                            <li onClick={() => changeLanguage('en')}>EN</li>
                            <li onClick={() => changeLanguage('fr')}>FR</li>
                            <li onClick={() => changeLanguage('de')}>DE</li>
                        </ul>
                    )}
                </div>
            </div>

            <div className="homePage__navbar__hamburger" onClick={handleMenuToggle}>
                <div className={`homePage__navbar__hamburger-lines ${menuOpen ? 'open' : ''}`}>
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
            </div>

            <div className={`homePage__navbar__hamburger-menu ${menuOpen ? 'active' : ''}`}>
                <ul className={`homePage__navbar__menu-items`}>
                    <li><a href="/Oplossingen" dangerouslySetInnerHTML={{ __html: t('homePageNavbar.solutions') }} /></li>
                    <li><a href="/Controller" dangerouslySetInnerHTML={{ __html:t('homePageNavbar.controller') }} /></li>
                    <li><a href="/SimulatieAanvragen" onClick={() => { trackClickToSimulationPage() }}>{t('homePageNavbar.simulation')}</a></li>
                    <li><a href="/Nieuws">{t('homePageNavbar.news')}</a></li>
                    <li><a href="/Contact">{t('homePageNavbar.contact')}</a></li>
                    <li><a href="#" onClick={() => loginWithRedirect()} className="homePage__dashboard__button">{t('homePageNavbar.dashboard')}</a></li>
                </ul>

                <div className="homePage__navbar-bottom">
                    <a href={pdf1}>{t('homePageNavbar.algemeneVoorwaarden')}</a><span> | </span>
                    <a href={pdf2}>{t('homePageNavbar.aankoopGoederenHardware')}</a>
                </div>
            </div>
        </nav>
    );
};

export default HomePageNavBar;
