import React from 'react';
import { useTranslation } from 'react-i18next';
import './sector.css';
import content from './SectorenContent.json';

const Sector = ({ sector }) => {
    const { t } = useTranslation();
    const language = t('language');
    const data = content[sector];

    if (!data) {
        return <div>{t('sectorNotFound.header')[language]}</div>;
    }

    return (
        <div className="sectoren__body">
            <h1 className="sectoren__header">{data.header[language]}</h1>
            {data.sections.map((section, index) => (
                <section key={index} className="sectoren__section">
                    {section.styling?.className ? (
                        <>
                            <div className={`sectoren__text ${section.styling?.className || ''}`}>
                                {section.text.title && <h2>{section.text.title[language]}</h2>}

                                {Array.isArray(section.text.paragraphs?.[language]) &&
                                    section.text.paragraphs[language].map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}

                                {Array.isArray(section.text.list?.[language]) && (
                                    <ul>
                                        {section.text.list[language].map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                )}

                                {Array.isArray(section.text.details?.[language]) &&
                                    section.text.details[language].map((detail, idx) => (
                                        <p key={idx}>{detail}</p>
                                    ))}

                                {section.text.title2 && <h2>{section.text.title2[language]}</h2>}

                                {Array.isArray(section.text.paragraphs2?.[language]) &&
                                    section.text.paragraphs2[language].map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}
                            </div>

                            {section.image && (
                                <div className="sectoren__image">
                                    <img
                                        src={require(`../../../Images/${section.image}`)}
                                        alt={section.text.title?.[language] || 'Afbeelding'}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {section.image && (
                                <img
                                    src={require(`../../../Images/${section.image}`)}
                                    alt={section.text.title?.[language] || 'Afbeelding'}
                                    className="sectoren__image"
                                />
                            )}
                            <div className={`sectoren__text ${section.styling?.className || ''}`}>
                                {section.text.title && <h2>{section.text.title[language]}</h2>}

                                {Array.isArray(section.text.paragraphs?.[language]) &&
                                    section.text.paragraphs[language].map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}

                                {Array.isArray(section.text.list?.[language]) && (
                                    <ul>
                                        {section.text.list[language].map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                )}

                                {Array.isArray(section.text.details?.[language]) &&
                                    section.text.details[language].map((detail, idx) => (
                                        <p key={idx}>{detail}</p>
                                    ))}

                                {section.text.title2 && <h2>{section.text.title2[language]}</h2>}

                                {Array.isArray(section.text.paragraphs2?.[language]) &&
                                    section.text.paragraphs2[language].map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}

                                {section.text.title3 && <h2>{section.text.title3[language]}</h2>}

                                {Array.isArray(section.text.paragraphs3?.[language]) &&
                                    section.text.paragraphs3[language].map((para, idx) => (
                                        <p key={idx}>{para}</p>
                                    ))}
                            </div>
                        </>
                    )}
                </section>
            ))}
        </div>
    );
};

export default Sector;
