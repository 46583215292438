import React from 'react';
import { useTranslation } from 'react-i18next';
import './powerblock.css';

import powerblock from '../../../../Images/powerblock.png';
import powerblockOpen from '../../../../Images/powerblockOpen.png';

const Powerblock = () => {
    const { t } = useTranslation();
    
    return (
        <div className="powerblockPage__container">
            <section className="powerblockPage__introduction">
                <h1>{t('powerblock.powerblockTitle')}</h1>
                <p>
                    {t('powerblock.powerblockDescription')}
                </p>
            </section>

            <section className="powerblockPage__content">
                <div className="powerblockPage__text-content">
                    <div className="powerblockPage__text">
                        <h2>{t('powerblock.whyChooseTitle')}</h2>
                        <p>
                            {t('powerblock.whyChooseDescription')}
                        </p>
                        <ul>
                            <li><strong>{t('powerblock.feature1')}:</strong> {t('powerblock.feature1Description')}</li>
                            <li><strong>{t('powerblock.feature2')}:</strong> {t('powerblock.feature2Description')}</li>
                            <li><strong>{t('powerblock.feature3')}:</strong> {t('powerblock.feature3Description')}</li>
                            <li><strong>{t('powerblock.feature4')}:</strong> {t('powerblock.feature4Description')}</li>
                        </ul>
                    </div>
                    
                    <div className="powerblockPage__features">
                        <h2>{t('powerblock.features')}</h2>
                        <ul>
                            <li><strong>{t('powerblock.feature1')}:</strong> {t('powerblock.feature1Description')}</li>
                            <li><strong>{t('powerblock.feature2')}:</strong> {t('powerblock.feature2Description')}</li>
                            <li><strong>{t('powerblock.feature3')}:</strong> {t('powerblock.feature3Description')}</li>
                            <li><strong>{t('powerblock.feature4')}:</strong> {t('powerblock.feature4Description')}</li>
                        </ul>
                    </div>
                </div>
                <div className="powerblockPage__image-block">
                    <img src={powerblock} alt={t('powerblock.powerblockTitle')} />
                </div>
            </section>

            <section className="powerblockPage__open-image">
                <img src={powerblockOpen} alt={t('powerblock.powerblockTitle')} />
            </section>
        </div>
    );
};

export default Powerblock;
