import React from 'react';
import './homePageHeader.css';
import { useTranslation } from 'react-i18next';
import HomePageNavBar from './homePageNavbar';
import DayAheadPriceChart from '../dayAheadPrice/dayAheadPriceChart';
import HomePageHeaderBody from './homePageHeaderBody';
import HomePageNewsAlert from './homePageNewsAlert';

const HomePageHeader = ({ scrollToNews, scrollToSimulatie }) => {
    const { t } = useTranslation();

    return (
        <div className="homePage__header">
            <HomePageNavBar isHomePage={true} />
            <HomePageNewsAlert scrollToNews={scrollToNews} />
            <div className='homePage__navbar__text__segment'>
                <p className='homePage__navbar__subtitle'>{t('homePageHeader.subtitle')}</p>
                <h1 className='homePage__navbar__title'>{t('homePageHeader.title')}</h1>
                <p className='homePage__navbar__description'>{t('homePageHeader.description')}</p>
            </div>
            <HomePageHeaderBody />
            <div className='homePage__navbar__dayAhead__segment'>
                <div className='homePage__navbar__text-section'>
                    <p className='homePage__navbar__subtitle' dangerouslySetInnerHTML={{ __html: t('homePageHeader.aiSolutionSubtitle') }} />
                    <h1 className='homePage__navbar__title'>{t('homePageHeader.aiSolutionTitle')}</h1>
                    <p dangerouslySetInnerHTML={{ __html: t('homePageHeader.aiSolutionDescription') }} />
                    <div className="homePage__navbar__links">
                        <a href="/Controller">{t('homePageHeader.controllerLink')}  <i className='fas fa-arrow-right' /></a>
                        <a onClick={scrollToSimulatie}>{t('homePageHeader.simulationLink')}  <i className='fas fa-arrow-right' /></a>
                    </div>
                </div>
                <div className="homePage__navbar__graph-section">
                    <div className="homePage__navbar__graph">
                        <DayAheadPriceChart dateToday={new Date()} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePageHeader;
